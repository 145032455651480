<script>
	import Layout from '../../layouts/main';
	import PageHeader from '@/components/page-header';
	import appConfig from '@/app.config';

	import Vue from 'vue';
	import axios from 'axios';
	import VueAxios from 'vue-axios';
	import Multiselect from "vue-multiselect";

	Vue.use(VueAxios, axios);
	axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

	export default {
		page: {
			title: '',
			meta: [
				{
					name: 'description',
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
			Multiselect,
		},
		data: function() {
			return {
				items: [
					{
						text: "View",
					},
					{
						text: "Holiday Sheet",
						active: true,
					},
				],
				corporateId: '',
				branchs: [],
				selectedBranch: {},
				holidayFile: null,
				uploadProgress: 0,
				uploading: false,
			};
		},
		mounted: function() {
			this.corporateId = localStorage.getItem('corporateId')
			this.getBranchList()
		},
		methods: {
			getBranchList: function() {
				this.axios.post('corporate/get-branch', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
				}).then(result => {
					this.branchs = [];
					for(let i = 0; i < result.data.data.length;i++) {
						this.branchs.push({
							branchId: result.data.data[i]._id,
							name: result.data.data[i].name,
						});
					}
					this.selectedBranch = this.branchs[0]
				}).catch(error => {
					console.log(error)
				})
			},
			addHolidayList: function() {
				this.uploading = true;
				let formData = new FormData();
				formData.append('file', this.holidayFile);
				formData.append('branchId', this.selectedBranch.branchId);
				this.axios.post('addHoliday', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					onUploadProgress: function(progress) {
						this.uploadProgress = parseInt(Math.round((progress.loaded / progress.total) * 100));
					}.bind(this),
				}).then(result => {
					this.uploading = false
					let statusCode = result.data.responseCode
					if (statusCode === 200) {
						window.location.reload()
					}
					if (statusCode === 400) {
						this.$toast.error(result.data.response, {
							position: "top-right",
							timeout: 5000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: "button",
							icon: true,
							rtl: false
						})
					}
				}).catch(error => {
					console.log(error)
				});
			},
			handleFileUpload(event) {
				this.holidayFile = event.target.files[0];
				/*this.axios.post('addCorporateEmployee', //TODO change this url
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}
				).then((response) => {
					console.log(response.data.data);
				});*/
			},
		},
	};
</script>
<style></style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<b-card title="New Company Holiday">
					<b-form @submit.prevent="addHolidayList($event)" enctype="multipart/form-data">
						<div class="row inner mb-2">
							<div class="col-md-6">
								<b-form-group class="mb-3" label="Select Branch" for="branch">
									<multiselect
										id="branch"
										:options="branchs"
										v-model="selectedBranch"
										class="helo"
										placeholder="Select Branch"
										:multiple="false"
										track-by="name"
										label="name"
										:allow-empty="false">
										<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
									</multiselect>
								</b-form-group>
							</div>
						</div>
						<div class="row inner mb-2">
							<div class="col-md-6">
								<b-form-group class="mb-3" label="Select .xlxs, .xls file" for="holiday">
									<b-form-file id="holiday"
										v-model="holidayFile"
										class="form-control-file"
										placeholder="Select file"
										@change="handleFileUpload($event)" plain></b-form-file>
								</b-form-group>
							</div>
						</div>
						<div class="row">
							<div class="col-md-2">
								<progress max="100" animated="true" striped="true" v-if="uploading" :value.prop="uploadProgress"></progress>
								<b-button class="btn-btn-outline-primary mt-4" v-if="!uploading" type="submit" variant="primary">Submit</b-button>
							</div>
						</div>
					</b-form>
				</b-card>
			</div>
		</div>
	</Layout>
</template>